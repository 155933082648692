import {css} from "styled-components";

export const THEME = {
    colors: {
        // green: '#5c8c46',
        // green: '#69a74a',
        green: '#618145',
        blue: 'rgba(121,197,255,1)',
    },
    spacing: {
        $1: 4,
        $1a: 6,
        $1b: 8,
        $1c: 10,
        $2: 16,
        $3: 24,
        $4: 28,
        $5: 32,
        $5b: 38,
        $6: 48,
    }
}

export const cssZIndexTop = css`
  z-index: 9999;
`
