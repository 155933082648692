import * as React from "react"
import {Helmet} from "react-helmet"
import {GlobalStyle} from "../ui/global"

export const GlobalWrapper: React.FC = ({children}) => {
    return (
        <>
            <GlobalStyle/>
            <Helmet>
                <link rel="icon" href="/favicon/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="title" content="Skittish"/>
                <meta name="description" content="A playful space for online events."/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://skittish.com/"/>
                <meta property="og:title" content="Skittish"/>
                <meta property="og:description" content="A playful space for online events."/>
                <meta property="og:image" content="https://skittish.com/card.png" />

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://skittish.com/"/>
                <meta property="twitter:title" content="Skittish"/>
                <meta property="twitter:description" content="A playful space for online events."/>
                <meta property="twitter:image" content="https://skittish.com/card.png"/>
                <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png"/>
                <title>Skittish</title>
            </Helmet>
            {children}
        </>
    )
}
