import {createGlobalStyle, css} from "styled-components";
import reset from "styled-reset"
import {THEME} from "./theme";

export const MODAL_CLASSES = {
  modal: '_modal',
  overlay: '_overlay',
}

const cssModals = css`

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  .${MODAL_CLASSES.modal} {
    outline: none;
  }

  .${MODAL_CLASSES.overlay} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
  }

`

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${cssModals};
  
  * {
    box-sizing: border-box;
  }
  
  html {
    background-color: ${THEME.colors.blue};
  }
  
  body {
    font-family: 'Sniglet', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.15;
    background-color: ${THEME.colors.green};
  }
`
